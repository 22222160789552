<template>
  <div class="result_wrapper">
    <p class="captcha q-mb-md text-center">Сравните условия, выберите лучшие и купите полис за пару минут</p>

    <div class="banner q-mb-xs">
      Проверьте данные для максимально <br> точного расчёта стоимости полиса ОСАГО
    </div>

    <div class="result_container">
      <q-expansion-item
          label="Ваши данные"
      >
        <div class="q-px-md bg-white" style="margin: 0 2px;">
          <div class="row column items-start justify-start">

            <p class="auto">Автомобиль</p>

            <p class="brand_model">{{ $store.getters['goverStore/getCarByKey']('brand') }}
              {{ $store.getters['goverStore/getCarByKey']('model') }}</p>

            <p class="engine q-mb-md">{{ $store.getters['goverStore/getCarByKey']('enginePower') }}</p>

            <p
                class="row items-center q-mb-md edit-button last"
                @click="goToTab('firstTab')"
            >
              <q-icon
                  color="primary"
                  class="q-mr-xs"
                  name="far fa-edit"
                  size=".9rem"
              />
              <span class="text-primary">Редактировать</span>
            </p>

<!--            <p class="adress-title">Адрес собственника</p>-->

<!--            <p class="adress q-mb-md">{{ adressValue }}</p>-->

<!--            <p-->
<!--                class="row items-center q-mb-md edit-button last"-->
<!--                @click="goToTab('thirdTab')"-->
<!--            >-->
<!--              <q-icon-->
<!--                  color="primary"-->
<!--                  class="q-mr-xs"-->
<!--                  name="far fa-edit"-->
<!--                  size=".9rem"-->
<!--              />-->
<!--              <span class="text-primary">Редактировать</span>-->
<!--            </p>-->
          </div>
        </div>
      </q-expansion-item>

      <div class="kbm row items-start justify-center column">
        <p class="title">Расчёт произведён с учётом истории <br> вождения (КБМ)</p>

        <p class="description">Полис будет оформлен на выбранных водителей</p>

        <p
            class="row items-center"
            @click="goToTab('thirdTab')"
        >
          <q-icon
              color="primary"
              class="q-mr-xs"
              name="far fa-edit"
              size=".9rem"
          />
          <span class="text-primary">Редактировать</span>
        </p>
      </div>

      <div class="row q-col-gutter-sm">
        <div
            v-for="(item, index) in companies"
            class="col-lg-6 col-md-6 col-sm-6 col-12"
        >
          <CardOsago
              :key="index"
              :data="item"
          />
        </div>
      </div>

    </div>


  </div>
</template>

<script setup>
import {useStore} from "vuex";
import {computed} from "vue";
import CardOsago from "@/views/components/CardOsago.vue";

const $store = useStore();

const adressValue = computed(() => {
  const personalInfo = localStorage.getItem('personalData');
  if (!!personalInfo && !!Object.keys(JSON.parse(personalInfo)).length) {
    return JSON.parse(personalInfo).address
  } else return ''
})

const companies = computed(() => $store.getters['goverStore/getCompanies'])

const goToTab = async (tabName) => {
  await $store.dispatch('goverStore/changeStateField', {field: 'tabName', value: tabName})
}
</script>


<style scoped lang="scss">
@import "src/css/variables";
@import "src/css/mixins";
.result_wrapper {

  .captcha {
    font-size: 12px;
    @include fontSize((12, 11));
    padding: 0 20px;
    color: $textColor;
  }

  .banner {
    padding: 10px;
    margin: 0 20px 10px;
    font-size: 13px;
    @include fontSize((13, 12, 11));
    color: $textColor;
    background-color: $secondaryBlue;
    border-radius: 5px;
  }

  .result_container {
    //background-color: #F5F7F9;
    margin: 5px;
    padding: 5px;

    .auto {
      margin: 15px 0;
      color: $textColor;
    }

    .adress-title {
      margin: 0 0 15px 0;
      color: $textColor;
    }

    .brand_model {
      margin: 0 0 5px;
    }

    .brand_model,
    .engine {
      font-size: 13px;
      line-height: 100%;
    }

    .edit-button {
      width: 100%;
      margin-bottom: 15px;

      &:not(.last) {
        border-bottom: 1px solid #DDDEDE;
        padding-bottom: 15px;
      }
    }

  }

  .kbm {
    padding: 10px;
    margin: 15px 1px;
    border: 1px solid #DDDEDE;
    border-left: 1px solid $greenColor;
    border-radius: 5px;
    background-color: white;

    .title {
      font-weight: 700;
      line-height: 110%;
      margin-bottom: 10px;
      font-size: 14px;
      @include fontSize((14, 13, 12));
    }

    .description {
      line-height: 110%;
      color: $textColor;
      margin-bottom: 10px;
      font-size: 13px;
      @include fontSize((13, 12, 11));
    }
  }
}
</style>
