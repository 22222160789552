<template>
  <section class="calc_wrapper">
    <div class="row items-center justify-start">
      <q-icon
          size="1rem"
          class="q-pa-sm"
          name="fas fa-arrow-left"
          style="color: #939393;"
          @click="back"
      />
      <p class="title" v-html="title"/>
    </div>
    <q-tabs
        v-model="tab"
        v-if="tab !== 'fourTab'"
        class="text-teal"
        content-class="tab_container"
    >
      <q-tab :disable="true" name="firstTab">
        <span>{{tab === 'firstTab' ? 'Автомобиль' : 'Шаг 1'}} </span>
      </q-tab>
      <q-icon class="icon-stepper" name="fas fa-chevron-right" />
<!--      <q-tab :disable="true" name="secondTab">-->
<!--        <span>{{tab === 'secondTab' ? 'Водители' : 'Шаг 2'}} </span>-->
<!--      </q-tab>-->
<!--      <q-icon class="icon-stepper" name="fas fa-chevron-right" />-->
      <q-tab :disable="true" name="thirdTab">
        <span>{{tab === 'thirdTab' ? 'Собственник' : 'Шаг 2'}} </span>
      </q-tab>
      <q-icon class="icon-stepper" name="fas fa-chevron-right" />
      <q-tab :disable="true" name="fourTab" label="Шаг 3"/>
    </q-tabs>

    <q-tab-panels
        v-model="tab"
        animated
        transition-prev="jump-up"
        transition-next="jump-up"
    >
      <q-tab-panel name="firstTab">
        <AutoData
            @back="back"
        />
      </q-tab-panel>

      <q-tab-panel name="secondTab">
      </q-tab-panel>

      <q-tab-panel name="thirdTab">
        <OwnerDetails
            @back="back"
        />
      </q-tab-panel>

      <q-tab-panel name="fourTab">
        <Result />
      </q-tab-panel>
    </q-tab-panels>
  </section>
</template>

<script setup>
import {computed, ref} from "vue";
import AutoData from "@/views/components/AutoData.vue";
import {useStore} from "vuex";
import OwnerDetails from "@/views/components/OwnerDetails.vue";
import Result from "@/views/components/Result.vue";

const tab = computed({
  get() {
    return $store.getters['goverStore/getActiveTab']
  },
  set(newValue){
    $store.dispatch('goverStore/changeStateField', { field: 'tabName', value: newValue })
  }
});
const $store = useStore();

const back = async () => {
  switch ($store.getters['goverStore/getActiveTab']){
    case "firstTab":
      await $store.dispatch('goverStore/changeStateField', {field: 'calcScreen', value: false});
      break
    case "secondTab":
      await $store.dispatch('goverStore/changeStateField', {field: 'tabName', value: 'firstTab'})
      break
    case "thirdTab":
      await $store.dispatch('goverStore/changeStateField', {field: 'tabName', value: 'firstTab'})
      break
    case "fourTab":
      await $store.dispatch('goverStore/changeStateField', {field: 'tabName', value: 'thirdTab'})
      break
  }
}

const title = computed(() => tab.value === 'fourTab' ? 'Результаты расчёта ОСАГО' : 'Данные для расчета')
</script>

<style scoped lang="scss">
@import "src/css/mixins";
.title{
  font-weight: bolder;
  font-size: 22px;
  @include fontSize((22, 20, 18));
  text-align: center;
  line-height: 120%;
  width: calc(100% - 40px - 16px);
}
</style>
