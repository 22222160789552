<template>
  <div class="container">
      <Main v-if="!calcScreen"/>
      <Calculate v-else/>
  </div>
</template>

<script setup>
import {computed, onMounted} from 'vue';
import {useStore} from "vuex";
import Calculate from "@/views/components/Calculate.vue";
import Main from "@/views/components/Main.vue";

const $store = useStore();

const calcScreen = computed(() => $store.getters['goverStore/getCalcScreen'])

onMounted(async () =>{
  const dataAuto = localStorage.getItem('dataAuto');

  if (!!dataAuto && !!Object.keys(JSON.parse(dataAuto)).length){
    await $store.dispatch('goverStore/setFromLocalStorage')
  }
})
</script>
