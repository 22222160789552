export const CHANGE_STATE_FIELD = (state, payload) => {
    state[payload.field] = payload.value
};

export const UPDATE_FIELD_CARD_BY_KEY = (state, payload) => {
    state.car[payload.field] = payload.value
};

export const UPDATE_OBJECT_CARD_BY_KEY = (state, payload) => {
    state.car[payload.objectName][payload.field] = payload.value
};

export const RESET_CAR_STATE = state => {
    state.car = {
            number: state.car.number,
            brand: '',
            model: '',
            yearIssue: '',
            enginePower: '',
            identifier: {
                number: '',
                type: '',
            },
            autoDocument: {
                type: '',
                number: '',
                yearIssue: ''
            }
    }
    setTimeout(() => {
        state.car.identifier.number = '';
        state.car.autoDocument.number = '';
    }, 0)
};

export const SAVE_LOCAL_STORAGE = state => {
    const dataAuto = JSON.stringify({
        car: state.car,
        goverNumber: state.goverNumber,
        calcScreen: state.calcScreen,
        tabName: state.tabName,
    });
    localStorage.setItem('dataAuto', dataAuto)
}

export const SET_FROM_LOCAL_STORAGE = state => {
    const dataAuto = JSON.parse(localStorage.getItem('dataAuto'));
    state.car = dataAuto.car;
    state.goverNumber = dataAuto.goverNumber;
    state.calcScreen = dataAuto.calcScreen;
    state.tabName = dataAuto.tabName;
}
