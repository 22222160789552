<template>
  <section class="main_screen">
    <h1 class="title text-center">ОСАГО онлайн</h1>

    <p class="subtitle text-center">
      В среднем клиенты нашего сервиса <br>
      экономят при покупке полиса ОСАГО
    </p>

    <p class="econom text-center">
      от 800 ₽ до 5 500 ₽
    </p>

    <div
        class="img-preview"
        style="background-image: url('/images/car.png')"
    />



    <div class="row items-start justify-between q-mb-sm main-input">
      <div class="v-mask-container q-mr-sm">
        <input
            v-model="registrationNumber"
            ref="registrationNumberRef"
            class="v-mask-input"
            :class="errorMessage && 'error'"
            placeholder="Гос. номер"
            v-maska:[options]
            @input="errorMessage = ''"
        >
        <div class="rus" style="background-image: url('/images/rus.png')"/>
      </div>
      <div
          class="icon-registration row items-center justify-center cursor-pointer text-white q-px-md"
          @click="checkGoverNumber"
      >
        <span class="q-mr-xs next-text">Далее</span>
        <q-icon
            name="fas fa-chevron-right"
            color="white"
        />
      </div>
    </div>
    <p v-if="errorMessage" v-text="errorMessage" class="q-ml-sm text-negative q-mb-sm" style="font-size: 12px; font-weight: 500; max-width: 540px; margin-left: auto; margin-right: auto;"/>

    <!--div class="no-number text-center">Ещё не получили номер?</div-->

    <div class="banner row items-start justify-between">
      <p>Мы делаем расчет в каждой страховой <br> компании, а вам предлагаем лучшее</p>
      <div class="heart" style="background-image: url('/images/heart.png')"/>
    </div>

    <p class="footer-text text-center">
      Экономия достигается за счет разницы в цене предложений разных <br> страховых компаний. Расчёт произведён по базе клиентов за октябрь 2022 года<br> РЕКЛАМА | SRAVNI.RU
    </p>
  </section>
</template>

<script setup>
import {computed, ref} from 'vue'
import {useQuasar} from "quasar";
import {useStore} from "vuex";
import { database } from "@/db";
import { vMaska } from "maska"

const registrationNumberRef = ref(null);
const errorMessage = ref('');
const $q = useQuasar();
const $store = useStore();

const options = ref({
  mask: "A ### AA ###",
  eager: true,
  tokens: {
    A: { pattern: /[А-Я]/, transform: chr => chr.toUpperCase() }
  }
})

const registrationNumber = computed({
  get(){
    return $store.getters['goverStore/getGoverNumber']
  },
  set(newValue){
    $store.dispatch('goverStore/changeStateField', { field: 'goverNumber', value: newValue })
  }
});
const checkGoverNumber = async () => {
  let valid = true
  let noNumber = true;
  const newRequest = {
    number: registrationNumber.value,
    brand: '',
    model: '',
    yearIssue: '',
    enginePower: '',
    identifier: {
      type: '',
      number: ''
    },
    autoDocument: {
      type: '',
      number: '',
      yearIssue: ''
    }
  }

  // if(!registrationNumber.value) {
  //   errorMessage.value = 'Обязательное поле'
  //   valid = false
  // }
  // if (!(registrationNumber.value.length >= 11)){
  //   errorMessage.value = 'Некорректный гос. номер'
  //   valid = false
  // }
  //
  // if (!valid) {
  //   $q.notify({
  //     type: 'negative',
  //     message: 'Некорректный Гос. номер'
  //   })
  //   return
  // }
  // else{
  //   errorMessage.value = ''
  // }

  for (const existNumber of database.existingStateNumbers){
    if (existNumber.number === registrationNumber.value){
      noNumber = false
      await $store.dispatch('goverStore/changeStateField', { field: 'car', value: existNumber })
      await $store.dispatch('goverStore/changeStateField', { field: 'calcScreen', value: true })
      break
    }
  }
  if (noNumber) {
    await $store.dispatch('goverStore/changeStateField', { field: 'car', value: newRequest })
    await $store.dispatch('goverStore/changeStateField', { field: 'calcScreen', value: true })
  }
}
</script>

<style lang="scss" scoped>
@import "src/css/variables";
@import "src/css/mixins";
.main_screen{
  .title{
    font-size: 30px;
    @include fontSize((30, 28, 26));
    line-height: 100%;
    font-weight: bolder;
    margin-bottom: 20px;

  }

  .subtitle{
    font-size: 16px;
    @include fontSize((16, 15, 14));
    color: $textColor;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .econom{
    font-size: 30px;
    @include fontSize((30, 28, 26, 24));
    color: $greenColor;
    font-weight: bolder;
    margin-bottom: 20px;
  }

  .img-preview{
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 100%;
    height: 130px;
    margin-bottom: 20px;
    @media (max-width: 680px) {
      height: 100px;
    }
  }

  .icon-registration{
    min-width: 40px;
    height: 40px;
    border-radius: 5px;
    background-color: $greenColor;
    @media (max-width: 470px) {
      .next-text{
        display: none;
      }
    }
  }

  .rus{
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    right: 25px;
    top: calc(50% - 8px);
  }

  .no-number{
    font-size: 16px;
    @include fontSize((16, 15, 14));
    color: $blueColor;
    margin-bottom: 20px;
  }

  .banner{
    background-color: $secondaryGreen;
    padding: 15px;
    max-width: 550px;
    margin: 0 auto 20px;


    p{
      font-size: 14px;
      @include fontSize((14, 13, 12));
      line-height: 100%;
      color: $textColor;
    }

    .heart{
      width: 25px;
      height: 25px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

  }

  .footer-text{
    font-size: 11px;
    @include fontSize((11, 10));
    color: $textColor;
  }
}
</style>
