<template>
    <div
        class="icon-default"
        :class="{
            'icon-default--pointer': pointer,
        }"
        :style="{
            width: width,
        }"
        @click="handleClick"
    >
        <img
            class="icon-default__image"
            :src="`/public/icons/${name}.svg`"
        />
        <slot/>
    </div>
</template>

<script setup>
const props = defineProps({
    name: {
        required: true,
        type: String,
        default: '',
    },
    pointer: {
        type: Boolean,
        default: false,
    },
    width: {
        type: String,
        default: 'auto',
    },
    height: {
        type: String,
        default: 'auto',
    },
});

const emit = defineEmits([ 'click' ]);

const handleClick = () => {
    emit('click');
};
</script>
