import { database } from "@/db";
export default function () {
    return {
        car: {
            number: '',
            brand: '',
            model: '',
            yearIssue: '',
            enginePower: '',
            identifier: {
                number: '',
                type: '',
            },
            autoDocument: {
                type: '',
                number: '',
                yearIssue: ''
            }
        },
        goverNumber:      '',
        calcScreen:       false,
        tabName:          'firstTab',

        carsBrands:         database.carBrands,
        enginePowers:       database.enginePowers,
        typeIdentifier:     database.typeIdentifier,
        typeAutoDocument:   database.typeAutoDocument,

        companies:          database.companiesOsago
    };
}
