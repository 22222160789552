import script from "./InputDefault.vue?vue&type=script&setup=true&lang=js"
export * from "./InputDefault.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QInput from 'quasar/src/components/input/QInput.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSkeleton,QInput});
