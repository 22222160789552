<template>
  <div class="auto-data">
    <div class="head row items-center justify-between q-mb-md no-wrap">
      <p>Данные автомобиля</p>
      <button
          class="row items-center justify-center"
          @click="reset"
      >
        <q-icon name="fas fa-redo"/>
        <span>Очистить поля</span>
      </button>
    </div>

    <div class="row q-col-gutter-lg q-mb-md">
      <div class="col-lg-6 col-md-6 col-sm-6 col-12">
        <div class="row items-start justify-start column">
          <p class="my-input-label">Гос. номер</p>
          <div class="v-mask-container q-mr-sm">
            <input
                v-model="registrationNumber"
                v-maska:[options]
                ref="registrationNumberRef"
                class="v-mask-input"
                :class="errorMessage && 'error'"
                placeholder="Гос. номер"
                @input="errorMessage = ''"
            >
          </div>
        </div>
        <p v-if="errorMessage" v-text="errorMessage" class="q-ml-sm text-negative q-mb-sm" style="font-size: 12px; font-weight: 500; max-width: 540px; margin-left: auto; margin-right: auto;"/>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-6 col-12">
        <div class="row items-start justify-start column">
          <p class="my-input-label">Марка</p>
          <q-select
              v-model="brandName"
              ref="brandNameRef"
              outlined
              style="width: 100%;"
              :options="carBrands"
              :hide-dropdown-icon="true"
              :hide-bottom-space="true"
              :rules="[value => !!value || 'Обязательное поле']"
              :no-error-icon="true"
              @update:model-value="$store.dispatch('goverStore/updateFieldCarByKey', { field: 'model', value: carModels[0] })"
          />
        </div>

      </div>

      <div class="col-lg-6 col-md-6 col-sm-6 col-12">
        <div class="row items-start justify-start column">
          <p class="my-input-label">Модель</p>
          <q-select
              v-model="modelName"
              ref="modelNameRef"
              outlined
              :options="carModels"
              :hide-dropdown-icon="true"
              :hide-bottom-space="true"
              :rules="[value => !!value || 'Обязательное поле']"
              :no-error-icon="true"
              style="width: 100%;"
          />
        </div>

      </div>

      <div class="col-lg-6 col-md-6 col-sm-6 col-12">
        <div class="row items-start justify-start column">
          <p class="my-input-label">Год выпуска</p>
          <InputDefault
              v-model="yearIssue"
              ref="yearIssueRef"
              mask="####"
              :rules="[
            value => !!value || 'Обязательное поле',
            value => !!value && value.length === 4 || 'Некорректный год выпуска'
        ]"
              :lazy-rules="true"
              :no-error-icon="true"
              :append="false"
              :hide-bottom-space="true"
              style="flex: 1; width: 100%;"
          />
        </div>

      </div>

      <div class="col-lg-6 col-md-6 col-sm-6 col-12">
        <div class="row items-start justify-start column">
          <p class="my-input-label">Мощность двигателя</p>
          <q-select
              v-model="enginePower"
              ref="enginePowerRef"
              outlined
              :options="enginePowers"
              :hide-dropdown-icon="true"
              :hide-bottom-space="true"
              :rules="[value => !!value || 'Обязательное поле']"
              :no-error-icon="true"
              style="width: 100%;"
          />
        </div>

      </div>

      <div class="col-lg-6 col-md-6 col-sm-6 col-12">
        <div class="row items-start justify-start column">
          <p class="my-input-label">Идентификатор</p>
          <q-select
              v-model="identityType"
              ref="identityTypeRef"
              outlined
              :options="identityTypes.map(item => item.type)"
              :hide-dropdown-icon="true"
              :hide-bottom-space="true"
              :rules="[value => !!value || 'Обязательное поле']"
              :no-error-icon="true"
              style="width: 100%;"
          />
        </div>

      </div>

      <div class="col-lg-6 col-md-6 col-sm-6 col-12">
        <div class="row items-start justify-start column q-mb-md">
          <p class="my-input-label">Номер {{ identityType }}</p>
          <InputDefault
              v-model="identityValue"
              ref="identityValueRef"
              :mask="typeIdentifierObj?.format || '##############'"
              :rules="typeIdentifierObj?.rules || [value => !!value || 'Обязательное поле']"
              :lazy-rules="true"
              :no-error-icon="true"
              :append="false"
              :hide-bottom-space="true"
              style="flex: 1; width: 100%;"
          />
        </div>

      </div>
    </div>

    <div class="sts-container">
      <p class="q-mb-md">Если вы только что купили автомобиль и будете ставить его на учёт со сменой номера - указывайте
        обязательно документ - ПТС. После получения нового рег. знака вы сможете вписать его в полис. Если смены номера
        не планируется - можете указать любой тип документа</p>

      <div class="row q-col-gutter-sm q-mb-md">
        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <div class="row items-start justify-start column">
            <p class="my-input-label">Документ на авто</p>
            <q-select
                v-model="autoDocumentType"
                ref="autoDocumentTypeRef"
                outlined
                :options="getAutoDocumentTypes.map(item => item.type)"
                :hide-dropdown-icon="true"
                :rules="[value => !!value || 'Обязательное поле']"
                :no-error-icon="true"
                :hide-bottom-space="true"
                style="width: 100%;"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <div class="row items-start justify-start column">
            <p class="my-input-label">Номер {{ autoDocumentType === 'СТС (Свидетельство)' ? 'СТС' : autoDocumentType }}</p>
            <InputDefault
                v-model="autoDocumentValue"
                ref="autoDocumentValueRef"
                :mask="typeAutoDocumentObj?.format || '##############'"
                :rules="typeAutoDocumentObj?.rules || [value => !!value || 'Обязательное поле']"
                :lazy-rules="true"
                :no-error-icon="true"
                :append="false"
                :hide-bottom-space="true"
                style="flex: 1; width: 100%;"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
          <div class="row items-start justify-start column">
            <p class="my-input-label">Дата выдачи
              {{ autoDocumentType === 'СТС (Свидетельство)' ? 'СТС' : autoDocumentType }}</p>
            <InputDefault
                v-model="autoDocumentDate"
                ref="autoDocumentDateRef"
                mask="##.##.####"
                :rules="[
                    value => !!value || 'Обязательное поле',
                    value => value === '__.__.____' || value === '' || value === null || value.length === 10 || /\d{2}.\d{2}.\d{4}/.test(value) || 'Укажите корректную дату',
                    value => value === '__.__.____' || value === '' || value === null || value.length === 10 || !isNaN(Date.parse(value.split('.').reverse().join('-'))) || 'Укажите корректную дату',
            ]"
                :lazy-rules="true"
                :no-error-icon="true"
                :append="false"
                :hide-bottom-space="true"
                style="flex: 1; width: 100%;"
            />
          </div>
        </div>
      </div>

      <div class="actions-box row items-center">
        <button @click="emit('back')" class="prev">НАЗАД</button>
        <button @click="nextTab" class="next">ПРОДОЛЖИТЬ</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import InputDefault from "@/components/InputDefault.vue";
import {ref, computed} from "vue";
import {useStore} from "vuex";
import {useQuasar} from "quasar";
import { vMaska } from "maska"

const $store = useStore();
const $q = useQuasar();
const emit = defineEmits(['back']);

const errorMessage = ref('');
const options = ref({
  mask: "A ### AA ###",
  eager: true,
  tokens: {
    A: { pattern: /[А-Я]/, transform: chr => chr.toUpperCase() }
  }
})


const registrationNumberRef = ref(null);
const brandNameRef = ref(null);
const modelNameRef = ref(null);
const yearIssueRef = ref(null);
const enginePowerRef = ref(null);
const identityTypeRef = ref(null);
const identityValueRef = ref(null);
const autoDocumentTypeRef = ref(null);
const autoDocumentValueRef = ref(null);
const autoDocumentDateRef = ref(null);

const nextTab = async () => {
  let registrationNumberValid = true;

  if(!registrationNumber.value) {
    errorMessage.value = 'Обязательное поле'
    registrationNumberValid = false
  }
  if (!(registrationNumber.value.length >= 11)){
    errorMessage.value = 'Некорректный гос. номер'
    registrationNumberValid = false
  }

  const brandNameValid = await brandNameRef.value.validate()
  const modelNameValid = await modelNameRef.value.validate()
  const yearIssueValid = await yearIssueRef.value.validate()
  const enginePowerValid = await enginePowerRef.value.validate()
  const identityTypeValid = await identityTypeRef.value.validate()
  const identityValueValid = await identityValueRef.value.validate()
  const autoDocumentTypeValid = await autoDocumentTypeRef.value.validate()
  const autoDocumentValueValid = await autoDocumentValueRef.value.validate()
  const autoDocumentDateValid = await autoDocumentDateRef.value.validate()


  const arrValid = [registrationNumberValid,
    brandNameValid,
    modelNameValid,
    yearIssueValid,
    enginePowerValid,
    identityTypeValid,
    identityValueValid,
    autoDocumentTypeValid,
    autoDocumentValueValid,
    autoDocumentDateValid
  ]

  const noValid = arrValid.some(item => !item)

  if (noValid) {
    $q.notify({
      type: 'negative',
      message: 'Заполните все поля'
    })
    return
  }

  await $store.dispatch('goverStore/changeStateField', {field: 'tabName', value: 'thirdTab'})
}

const reset = async () => {
  await $store.dispatch('goverStore/resetCarState')

  setTimeout(async () => {
    await brandNameRef.value.resetValidation()
    await modelNameRef.value.resetValidation()
    await yearIssueRef.value.resetValidate()
    await enginePowerRef.value.resetValidation()
    await identityTypeRef.value.resetValidation()
    await identityValueRef.value.resetValidate()
    await autoDocumentTypeRef.value.resetValidation()
    await autoDocumentValueRef.value.resetValidate()
    await autoDocumentDateRef.value.resetValidate()
  })
}

const registrationNumber = computed({
  get() {
    return $store.getters['goverStore/getCarByKey']('number')
  },
  set(newValue) {
    $store.dispatch('goverStore/updateFieldCarByKey', {field: 'number', value: newValue})
  }
});

const brandName = computed({
  get() {
    return $store.getters['goverStore/getCarByKey']('brand')
  },
  set(newValue) {
    $store.dispatch('goverStore/updateFieldCarByKey', {field: 'brand', value: newValue})
  }
});

const modelName = computed({
  get() {
    return $store.getters['goverStore/getCarByKey']('model')
  },
  set(newValue) {
    $store.dispatch('goverStore/updateFieldCarByKey', {field: 'model', value: newValue})
  }
});

const yearIssue = computed({
  get() {
    return $store.getters['goverStore/getCarByKey']('yearIssue')
  },
  set(newValue) {
    $store.dispatch('goverStore/updateFieldCarByKey', {field: 'yearIssue', value: newValue})
  }
});

const enginePower = computed({
  get() {
    return $store.getters['goverStore/getCarByKey']('enginePower')
  },
  set(newValue) {
    $store.dispatch('goverStore/updateFieldCarByKey', {field: 'enginePower', value: newValue})
  }
});

const identityType = computed({
  get() {
    return $store.getters['goverStore/getIdentifier']('type')
  },
  set(newValue) {
    $store.dispatch('goverStore/updateObjectCarByKey', {objectName: 'identifier', field: 'type', value: newValue})
  }
});

const identityValue = computed({
  get() {
    return $store.getters['goverStore/getIdentifier']('number')
  },
  set(newValue) {
    $store.dispatch('goverStore/updateObjectCarByKey', {objectName: 'identifier', field: 'number', value: newValue})
  }
});

const autoDocumentType = computed({
  get() {
    return $store.getters['goverStore/getAutoDocument']('type')
  },
  set(newValue) {
    $store.dispatch('goverStore/updateObjectCarByKey', {objectName: 'autoDocument', field: 'type', value: newValue})
  }
});

const autoDocumentValue = computed({
  get() {
    return $store.getters['goverStore/getAutoDocument']('number')
  },
  set(newValue) {
    $store.dispatch('goverStore/updateObjectCarByKey', {objectName: 'autoDocument', field: 'number', value: newValue})
  }
});

const autoDocumentDate = computed({
  get() {
    return $store.getters['goverStore/getAutoDocument']('yearIssue')
  },
  set(newValue) {
    $store.dispatch('goverStore/updateObjectCarByKey', {
      objectName: 'autoDocument',
      field: 'yearIssue',
      value: newValue
    })
  }
});

const carBrands = computed(() => $store.getters['goverStore/getCarsBrands'])
const carModels = computed(() => $store.getters['goverStore/getCarsModels'])
const enginePowers = computed(() => $store.getters['goverStore/getEnginePowers'])
const identityTypes = computed(() => $store.getters['goverStore/getIdentifityTypes'])
const getAutoDocumentTypes = computed(() => $store.getters['goverStore/getAutoDocumentTypes'])
const typeIdentifierObj = computed(() => $store.getters['goverStore/typeIdentifierObj'](identityType.value))
const typeAutoDocumentObj = computed(() => $store.getters['goverStore/typeAutoDocumentObj'](autoDocumentType.value))
</script>

<style scoped lang="scss">
@import "src/css/variables";
@import "src/css/mixins";
.auto-data {
  .head {
    p {
      font-weight: 700;
      font-size: 16px;
      @include fontSize((16, 14, 12));
    }

    button {
      color: $textColor;

      span {
        margin-left: 5px;
        white-space: nowrap;
      }
    }
  }

  .sts-container {
    background-color: $secondaryBlue;
    font-size: 12px;
    padding: 15px;
    border-radius: 5px;
  }

  .actions-box {
    justify-content: flex-end;
    @media (max-width: 600px) {
      justify-content: space-between;
    }
    .next {
      background-color: $greenColor;
      color: white;
      padding: 10px;
      border-radius: 5px;
      flex: 1;
      max-width: 150px;
      @media (max-width: 600px) {
        max-width: unset;
      }
    }

    .prev {
      border: 1px solid $textColor;
      background-color: white;
      color: $textColor;
      padding: 10px;
      border-radius: 5px;
      margin-right: 10px;
      flex: 1;
      max-width: 150px;
      @media (max-width: 600px) {
        max-width: unset;
      }
    }
  }
}
</style>
