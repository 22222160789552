export const database = {
    existingStateNumbers: [
        {
            number: 'T 702 BY 777',
            brand: '',
            model: '',
            yearIssue: '',
            enginePower: '',
            identifier: {
                type: '',
                number: ''
            },
            autoDocument: {
                type: '',
                number: '',
                yearIssue: ''
            }
        },
        {
            number: 'Т 598 ХТ 750',
            brand: 'Hyundai',
            model: 'Creta',
            yearIssue: '2017',
            enginePower: '150 л.с. / 110,33 кВт',
            identifier: {
                type: 'VIN',
                number: 'Z94G3813DHR047012'
            },
            autoDocument: {
                type: 'СТС (Свидетельство)',
                number: '99 19 307516',
                yearIssue: '20.03.2020'
            }
        },
        {
            number: 'М 394 ТЕ 799',
            brand: 'Chery',
            model: 'Amulet',
            yearIssue: '2017',
            enginePower: '150 л.с. / 110,33 кВт',
            identifier: {
                type: 'VIN',
                number: 'Z94G3813DHR047012'
            },
            autoDocument: {
                type: 'СТС (Свидетельство)',
                number: '99 19 307516',
                yearIssue: '20.03.2020'
            }
        },
    ],

    carBrands: [
        { name: 'Hyundai', models: ['Creta', 'SOLARIS', 'ELANTRA', 'SONATA']  },
        { name: 'Chery',   models: ['Amulet', 'Tigo']  },
    ],

    enginePowers: ['150 л.с. / 110,33 кВт', '160 л.с. / 110,33 кВт', '170 л.с. / 110,33 кВт'],

    typeIdentifier:  [
        { type: 'VIN', format: 'XXXXXXXXXXXXXXXXX', rules: [value => !!value && value.length === 17 || 'Некорректный номер VIN'] },
        { type: 'NIV', format: 'XXXXXXXXXXXXX',     rules: [value => !!value && value.length === 13 || 'Некорректный номер NIV'] },
    ],

    typeAutoDocument:  [
        { type: 'СТС (Свидетельство)', format: '## ## ######', rules: [value => !!value && value.length === 12 || 'Некорректный номер СТС'] },
        { type: 'ПТС',                 format: '## ## ####',     rules: [value => !!value && value.length === 10 || 'Некорректный номер ПТС'] },
    ],

    companiesOsago: [
        { img: 'images/companies/rosGos.png', rating: 5,  perconalAcc: 'ОС №0001-03', countPlacement: 53, price: 2897, url: 'https://rayapp.store/apps/payment/ios_3613/2897' },
        { img: 'images/companies/ingoStrah.png', rating: 4.5,  perconalAcc: 'ОС №0928-03', countPlacement: 104, price: 3052, url: 'https://rayapp.store/apps/payment/ios_35356/3052' },
        { img: 'images/companies/yougoria.png', rating: 5,  perconalAcc: 'ОС №0001-03', countPlacement: 1, price: null, url: 'https://rayapp.store/apps/payment/ios_34534/3467' },
    ]
}
