export const changeStateField = ({ commit, dispatch }, payload) => {
    commit('CHANGE_STATE_FIELD', payload);
    dispatch("saveLocalStorage")

};

export const updateFieldCarByKey = ({ commit, dispatch }, payload) => {
    commit('UPDATE_FIELD_CARD_BY_KEY', payload);
    dispatch("saveLocalStorage")
};

export const updateObjectCarByKey = ({ commit, dispatch }, payload) => {
    commit('UPDATE_OBJECT_CARD_BY_KEY', payload);
    dispatch("saveLocalStorage")
};

export const resetCarState = ({ commit, dispatch }) => {
    commit('RESET_CAR_STATE');
    dispatch("saveLocalStorage")
};

export const saveLocalStorage = ({commit}) => {
    commit('SAVE_LOCAL_STORAGE');
};

export const setFromLocalStorage = ({commit}) => {
    commit('SET_FROM_LOCAL_STORAGE');
};
