export const getCalcScreen = state => state.calcScreen;
export const getActiveTab = state => state.tabName;
export const getCarByKey = state => key => state.car[key];
export const getCar = state => state.car;
export const getCarsBrands = state => state.carsBrands.map(item => item.name);
export const getCarsModels = state => state.carsBrands.find(({ name }) => name === state.car.brand)?.models
export const getEnginePowers = state => state.enginePowers
export const getIdentifityTypes = state => state.typeIdentifier;
export const getAutoDocumentTypes = state => state.typeAutoDocument;
export const getIdentifier = state => key => state.car.identifier[key]
export const getAutoDocument = state => key => state.car.autoDocument[key]
export const typeIdentifierObj = state => key => state.typeIdentifier.find(({ type }) => type === key)
export const typeAutoDocumentObj = state => key => state.typeAutoDocument.find(({ type }) => type === key)
export const getGoverNumber = state => state.goverNumber;
export const getCompanies = state => state.companies
