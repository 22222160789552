<template>
  <section class="owner-details">

    <div class="head row items-center justify-between q-mb-md no-wrap">
      <p>Данные собственника</p>
      <button
          class="row items-center justify-center"
          @click="clearFields"
      >
        <q-icon name="fas fa-redo" />
        <span>Очистить поля</span>
      </button>
    </div>

    <div class="row q-col-gutter-sm q-mb-md">
      <div class="col-lg-6 col-md-6 col-sm-6 col-12">
        <div class="row items-start justify-start column q-mb-sm">
          <p class="my-input-label">Фамилия</p>
          <InputDefault
              v-model="form.surName"
              ref="surNameRef"
              :rules="[
            value => !!value || 'Обязательное поле',
        ]"
              :lazy-rules="true"
              :no-error-icon="true"
              :append="false"
              :hide-bottom-space="true"
              style="flex: 1; width: 100%;"
              @update:model-value="updateLocalStorage"
          />
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-6 col-12">
        <div class="row items-start justify-start column q-mb-sm">
          <p class="my-input-label">Имя</p>
          <InputDefault
              v-model="form.name"
              ref="nameRef"
              :rules="[
              value => !!value || 'Обязательное поле',
        ]"
              :lazy-rules="true"
              :no-error-icon="true"
              :append="false"
              :hide-bottom-space="true"
              style="flex: 1; width: 100%;"
              @update:model-value="updateLocalStorage"
          />
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-6 col-12">
        <div class="row items-start justify-start column q-mb-sm">
          <p class="my-input-label">Отчество</p>
          <InputDefault
              v-model="form.lastName"
              ref="lastNameRef"
              :rules="[
              value => !!value || 'Обязательное поле',
        ]"
              :lazy-rules="true"
              :no-error-icon="true"
              :append="false"
              :hide-bottom-space="true"
              style="flex: 1; width: 100%;"
              @update:model-value="updateLocalStorage"
          />
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-6 col-12">
        <div class="row items-start justify-start column q-mb-sm">
          <p class="my-input-label">Дата рождения</p>
          <InputDefault
              v-model="form.happyDate"
              ref="happyDateRef"
              :rules="[
                    value => !!value || 'Обязательное поле',
                    value => value === '__.__.____' || value === '' || value === null || value.length === 10 || /\d{2}.\d{2}.\d{4}/.test(value) || 'Укажите корректную дату',
                    value => value === '__.__.____' || value === '' || value === null || value.length === 10 || !isNaN(Date.parse(value.split('.').reverse().join('-'))) || 'Укажите корректную дату',
          ]"
              mask="##.##.####"
              :lazy-rules="true"
              :no-error-icon="true"
              :append="false"
              :hide-bottom-space="true"
              style="flex: 1; width: 100%;"
              @update:model-value="updateLocalStorage"
          />
        </div>
      </div>

      <div class="col-12">
        <p class="q-mb-sm contact"><b>Контактные данные</b></p>

        <p class="q-mb-sm">Телефон нужен для получения кода <br> подтверждения и расчёта ОСАГО, а на <br> указанную почту мы вышлем готовый полис</p>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-6 col-12">
        <div class="row items-start justify-start column q-mb-sm">
          <p class="my-input-label">Электронна почта</p>
          <InputDefault
              v-model="form.email"
              ref="emailRef"
              :rules="[
            value => !!value || 'Обязательное поле',
        ]"
              :lazy-rules="true"
              :no-error-icon="true"
              :append="false"
              :hide-bottom-space="true"
              style="flex: 1; width: 100%;"
              @update:model-value="updateLocalStorage"
          />
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-6 col-12">
        <div class="row items-start justify-start column q-mb-md">
          <p class="my-input-label">Номер телефона</p>
          <InputDefault
              v-model="form.phone"
              ref="phoneRef"
              :rules="[
            value => !!value || 'Обязательное поле',
          ]"
              mask="+7 (###) ###-##-##"
              placeholder="+7 (___) ___-__-__"
              :lazy-rules="true"
              :no-error-icon="true"
              :append="false"
              :hide-bottom-space="true"
              style="flex: 1; width: 100%;"
              @update:model-value="updateLocalStorage"
          />
        </div>
      </div>

      <!--div class="col-12">
        <div class="q-mb-md">
          <q-checkbox
              v-model="form.agreement"
              color="green"
              dense
              @update:model-value="updateLocalStorage"
          >
            <div class="q-ml-xs">Согласен с </div>
          </q-checkbox>
          <a
              href="https://www.sravni.ru/promo/soglasie-na-obrabotku-personalnyh-dannyh/?extern_browser=true"
              target="_blank"
          >
            правилами предоставления <br> информации
          </a>
        </div>
      </div-->
    </div>

    <div class="actions-box row items-center justify-between">
      <button @click="emit('back')" class="prev">НАЗАД</button>
      <button @click="next" class="next">ПРОДОЛЖИТЬ</button>
    </div>
  </section>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import InputDefault from "@/components/InputDefault.vue";
import {useQuasar} from "quasar";
import {useStore} from "vuex";

const emit = defineEmits(['back']);
const $q = useQuasar();
const $store = useStore();

const form = ref({
  surName: '',
  name: '',
  lastName: '',
  happyDate: '',
  email: '',
  phone: '',
  agreement: true
})

const surNameRef = ref(null);
const nameRef = ref(null);
const lastNameRef = ref(null);
const happyDateRef = ref(null);
// const pasportNumbersRef = ref(null);
// const dateGetPassportRef = ref(null);
// const addressRef = ref(null);
// const roomNumberRef = ref(null);
const emailRef = ref(null);
const phoneRef = ref(null);

const clearFields = async () => {
  form.value = {
    surName: '',
    name: '',
    lastName: '',
    happyDate: '',
    email: '',
    phone: '',
    agreement: true
  }
  await surNameRef.value.resetValidate()
  await nameRef.value.resetValidate()
  await lastNameRef.value.resetValidate()
  await happyDateRef.value.resetValidate()
  // await pasportNumbersRef.value.resetValidate()
  // await dateGetPassportRef.value.resetValidate()
  // await addressRef.value.resetValidate()
  // await roomNumberRef.value.resetValidate()
  await emailRef.value.resetValidate()
  await phoneRef.value.resetValidate()
}

const updateLocalStorage = () => localStorage.setItem('personalData', JSON.stringify(form.value));

const next = async () => {
  const surNameRefValidate = await surNameRef.value.validate();
  const nameRefValidate = await nameRef.value.validate();
  const lastNameRefValidate = await lastNameRef.value.validate();
  const happyDateRefValidate = await happyDateRef.value.validate();
  const emailRefValidate = await emailRef.value.validate();
  const phoneRefValidate = await phoneRef.value.validate();

  const arrValid = [surNameRefValidate,
    nameRefValidate,
    lastNameRefValidate,
    happyDateRefValidate,
    emailRefValidate,
    phoneRefValidate]

  const noValid = arrValid.some(item => !item);
  const agreemnt = form.value.agreement;

  if (noValid) {
    $q.notify({
      type: 'negative',
      message: 'Заполните все поля'
    })
    return
  }

  if (!agreemnt){
    $q.notify({
      type: 'negative',
      message: 'Согласитесь с правилами предоставления информации'
    })
    return
  }

  await $store.dispatch('goverStore/changeStateField', {field: 'tabName', value: 'fourTab'})

}

onMounted(() => {
  const personalData = localStorage.getItem('personalData');
  if (!!personalData && !!Object.keys(JSON.parse(personalData)).length){
    form.value = JSON.parse(personalData);
  }
})
</script>

<style scoped lang="scss">
@import "src/css/variables";
@import "src/css/mixins";
.owner-details{
  .head {
    p {
      font-weight: 700;
      font-size: 16px;
      @include fontSize((16, 14, 12));
    }

    button {
      color: $textColor;

      span {
        margin-left: 5px;
        white-space: nowrap;
      }
    }
  }

  .strah-container{
    background-color: $secondaryBlue;
    padding: 15px;
    border-radius: 5px;
    line-height: 120%;
  }

  .contact{
    font-size: 20px;
  }

  a{
    color: $blueColor;
    text-decoration: none;
  }

  .actions-box{
    justify-content: flex-end;
    @media (max-width: 600px) {
      justify-content: space-between;
    }
    .next {
      background-color: $blueColor;
      color: white;
      padding: 10px;
      border-radius: 5px;
      flex: 1;
      max-width: 150px;
      @media (max-width: 600px) {
        max-width: unset;
      }
    }

    .prev {
      border: 1px solid $textColor;
      color: $textColor;
      padding: 10px;
      border-radius: 5px;
      margin-right: 10px;
      flex: 1;
      max-width: 150px;
      @media (max-width: 600px) {
        max-width: unset;
      }
    }
  }
}
</style>
