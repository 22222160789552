<template>
    <div class="input-default" :class="key">
        <TransitionDefault>
            <q-skeleton
                v-if="loading"
                class="input-default__skeleton"
                type="QInput"
            />

            <q-input
                v-else
                :model-value="modelValue"
                ref="inputRef"
                :outlined="outlined"
                :rounded="rounded"
                :filled="filled"
                :standout="standout"
                :input-style="inputStyle"
                :autocomplete="autocomplete"
                :autogrow="autogrow"
                :counter="maxLength > 0"
                :disable="disable"
                :lazy-rules="lazyRules"
                :mask="mask"
                :fill-mask="fillMask"
                :maxlength="maxLength"
                :placeholder="placeholder"
                :readonly="readonly"
                :rules="rules"
                :borderless="borderless"
                :hide-bottom-space="hideBottomSpace"
                :dense="dense"
                :type="type"
                :unmasked-value="unmaskedValue"
                :loading="inputLoading"
                :label="label"
                :dark="dark"
                :no-error-icon="noErrorIcon"
                @update:model-value="handleInput"
                @focus="handleFocus"
                @blur="handleBlur"
                @keyup.enter="handleEnterPressed"
            >
                <template v-slot:loading>
                    <div class="loading-animate" />
                </template>

                <template
                    v-if="append"
                    v-slot:append
                >
                    <div>
                        <slot name="append" />
                        <IconDefault
                            v-if="!readonly && clearable"
                            name="plus"
                            style="rotate: 45deg;"
                            pointer
                            @click="clearInput"
                        />
                    </div>
                </template>

                <template
                    v-if="hint"
                    v-slot:hint
                >
                    {{ hint }}
                </template>
            </q-input>
        </TransitionDefault>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import IconDefault from './IconDefault.vue';
import TransitionDefault from './TransitionDefault.vue';

const props = defineProps({
    modelValue: {
        type: [ String, Number ],
        default: null,
    },

    inputStyle:    { type: String, default: null },
    autocomplete:  { type: String, default: null },
    autogrow:      { type: Boolean, default: false },
    disable:       { type: Boolean, default: false },
    outlined:      { type: Boolean, default: false },
    rounded:       { type: Boolean, default: true },
    filled:        { type: Boolean, default: false },
    standout:      { type: [ Boolean, String ], default: false },
    lazyRules:     { type: Boolean, default: true },
    loading:       { type: Boolean, default: false },
    fillMask:      { type: [ Boolean, String ], default: false },
    mask:          { type: String, default: '' },
    label:         { type: String, default: undefined },
    hint:          { type: String, default: null },
    maxLength:     { type: [ Number, Boolean ], default: undefined },
    placeholder:   { type: String, default: null },
    readonly:      { type: Boolean, default: false },
    rules:         {
        type: Array,
        default() {
            return [];
        },
    },
    borderless:      { type: Boolean, default: true },
    dense:           { type: Boolean, default: false },
    hideBottomSpace: { type: Boolean, default: false },
    type:            { type: String,  default: 'text' },
    unmaskedValue:   { type: Boolean, default: false },
    clearable:       { type: Boolean, default: false },
    inputLoading:    { type: Boolean, default: false },
    dark:            { type: Boolean, default: false },
    noErrorIcon:     { type: Boolean, default: false },
    append:          { type: Boolean, default: true },
});

const emit = defineEmits([ 'input', 'update:model-value', 'focus', 'blur', 'enter' ]);
const inputRef = ref(null);

const key = ref(0);
const handleInput = (value) => {
    emit('input', value);
    emit('update:model-value', value);
    key.value = key.value + 1 || 0;
};

const focus = () => {
    inputRef.value.focus();
};

const blur = () => {
    inputRef.value.blur();
};

const clearInput = () => {
    handleInput(null);
    focus();
};

const handleFocus = (event) => {
    if (event.sourceCapabilities) {
        emit('focus');
    }
};

const handleBlur = (event) => {
    if (event.sourceCapabilities) {
        emit('blur');
    }
};

const handleEnterPressed = () => {
    emit('enter');
};

const validate = async () => {
    return await inputRef.value.validate();
};

const resetValidate = async () => {
  return await inputRef.value.resetValidation();
};

const getInput = () => {
    return inputRef.value.nativeEl;
};

const setSelectionRange = (position) => {
    if (typeof getInput().setSelectionRange === 'function') {
        getInput().setSelectionRange(position, position);
    }
};

const getSelectionStart = () => {
    return getInput().selectionStart || null;
};

defineExpose({
    focus,
    blur,
    validate,
    clearInput,
    getInput,
    getSelectionStart,
    setSelectionRange,
    resetValidate
});
</script>
