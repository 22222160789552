<template>
  <div
      class="card-wrapper__osago"
      :class="!data.price && 'disable'"
  >

    <div class="head row items-center justify-between q-mb-sm">
      <div class="img" :style="{ 'background-image': `url(${data.img})` }"/>
      <p class="personal-account">{{data.perconalAcc}}</p>
    </div>

    <div class="row items-center q-mb-mb">
      <p class="q-mr-xs" style="min-width: 20px;">{{data.rating}}</p> <Rating :count="data.rating" />
    </div>

    <p class="placements">
      {{declineWord(data.countPlacement, 'офис в вашем регионе', 'офиса в вашем региона', 'офисов в вашем регионе')}}
    </p>

    <div v-if="data.price" class="row items-center justify-between">
      <div class="price row justify-start items-start column">
        <p class="q-mb-xs price-title">Стоимость</p>
        <p class="price-count">{{addSpacesToNumber(data.price) + ' ₽'}}</p>
      </div>
      <button
          class="btn-buy"
          @click="goToPage(data.url)"
      >
        купить полис
      </button>
    </div>
    <div v-else>
      <p class="no-price">
        <q-icon name="far fa-times-circle" size=".9rem" />
        Расчёт недоступен
      </p>
    </div>
  </div>
</template>

<script setup>
import Rating from "@/components/Rating.vue";

const props = defineProps({
  data: { type: Object, default: {} }
})

const goToPage = (url) => {
  window.open(url, '_blank')
}

const addSpacesToNumber = (number, isInputNumberCounter = false) => {
  if (number == null)
    number = '0';

  if (typeof number !== 'string')
    number = number.toString();

  number = number.split('.');
  number[0] = number[0].replace(/\s/g, '');
  number[0] = number[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  number = number.join('.');

  return number;
}

const declineWord = (number, word1, word2, word5, spaceForNumber = false) => {
  let lastDigit = number % 10;
  if (number % 100 >= 11 && number % 100 <= 19) {
    return spaceForNumber ? addSpacesToNumber(+number) + ' ' + word5 : number + ' ' + word5;
  }
  else if (lastDigit === 1) {
    return spaceForNumber ? addSpacesToNumber(+number) + ' ' + word1 : number + ' ' + word1;
  }
  else if (lastDigit >= 2 && lastDigit <= 4) {
    return spaceForNumber ? addSpacesToNumber(+number) + ' ' + word2 : number + ' ' + word2;
  }
  else {
    return spaceForNumber ? addSpacesToNumber(+number) + ' ' + word5 : number + ' ' + word5;
  }
}
</script>

<style scoped lang="scss">
@import "src/css/variables";
.card-wrapper__osago{
  padding: 15px;
  background-color: white;
  border: 1px solid #DDDEDE;
  margin-bottom: 5px;

  &.disable{
    opacity: .6;
  }

  .img{
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100px;
    height: 20px;
  }

  .personal-account{
    font-size: 12px;
    color: $textColor;
  }

  .placements{
    font-size: 12px;
    color: $textColor;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #DDDEDE;
  }

  .price-title{
    color: $textColor;
  }

  .price-count{
    font-weight: 700;
    font-size: 22px;
    line-height: 120%;
  }

  .btn-buy{
    background-color: $greenColor;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
  }

  .no-price{
    color: $textColor;
  }
}
</style>
